

























































































































































import { Component, Vue, Model } from "vue-property-decorator";
import ShortKeyInput from "./ShortKeyInput.vue";
import ShortKeyService from "@/views/setup/ShortKeyService";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import i18n from "@/i18n";
import color from "../../assets/utils/color";
import Axios from "axios";
import { SearchResult } from "../../models/SearchResult";

extend("required", {
  ...required,
  message: (_: string, values: any) => i18n.t("validations.required", [_])
} as any);

@Component({
  components: {
    ShortKeyInput,
    ValidationProvider,
    ValidationObserver
  }
})
// @Component
export default class ShortKey extends Vue {
  addCode: any = {};
  editCode: any = {} ;
  searchCode: any = {};
  deleteCode: any = {};
  refreshCode: any = {};
  detailCode: any = {};
  saveCode: any = {};
  quitCode: any = {};
  completeCode: any = {};
  printCode: any = {};
  moveCode: any = {};

  shortKeyService = new ShortKeyService();

  constructor() {
    super();
  }

  createKeyCode() {
    switch (this.addCode.nameCode) {
      case this.editCode.nameCode:
      case this.searchCode.nameCode:
      case this.deleteCode.nameCode:
      case this.refreshCode.nameCode:
      case this.detailCode.nameCode:
      case this.saveCode.nameCode:
      case this.quitCode.nameCode:
      case this.completeCode.nameCode:
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.editCode.nameCode) {
      case this.searchCode.nameCode:
      case this.deleteCode.nameCode:
      case this.refreshCode.nameCode:
      case this.detailCode.nameCode:
      case this.saveCode.nameCode:
      case this.quitCode.nameCode:
      case this.completeCode.nameCode:
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.searchCode.nameCode) {
      case this.deleteCode.nameCode:
      case this.refreshCode.nameCode:
      case this.detailCode.nameCode:
      case this.saveCode.nameCode:
      case this.quitCode.nameCode:
      case this.completeCode.nameCode:
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.deleteCode.nameCode) {
      case this.refreshCode.nameCode:
      case this.detailCode.nameCode:
      case this.saveCode.nameCode:
      case this.quitCode.nameCode:
      case this.completeCode.nameCode:
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.refreshCode.nameCode) {
      case this.detailCode.nameCode:
      case this.saveCode.nameCode:
      case this.quitCode.nameCode:
      case this.completeCode.nameCode:
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.detailCode.nameCode) {
      case this.saveCode.nameCode:
      case this.quitCode.nameCode:
      case this.completeCode.nameCode:
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.saveCode.nameCode) {
      case this.quitCode.nameCode:
      case this.completeCode.nameCode:
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.quitCode.nameCode) {
      case this.completeCode.nameCode:
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.completeCode.nameCode) {
      case this.printCode.nameCode:
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    switch (this.printCode.nameCode) {
      case this.moveCode.nameCode:
        return this.$vs.notify({
          title: "Chú ý",
          text: "Phím chức năng bị trùng!",
          color: "danger"
        });
    }
    let list = [
      this.addCode,
      this.editCode,
      this.searchCode,
      this.deleteCode,
      this.refreshCode,
      this.detailCode,
      this.saveCode,
      this.quitCode,
      this.completeCode,
      this.printCode,
      this.moveCode
    ];
    this.shortKeyService.insert(list);
    return this.$vs.notify({
      title: "Chúc mừng",
      text: "Bạn đã cập nhật thành công!",
      color: "success"
    });
  }

  resetKeyCode() {
    this.addCode = {
      name: "addCode",
      nameCode: "F1",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "F1"
    };
    this.editCode = {
      name: "editCode",
      nameCode: "F2",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "F2"
    };
    this.searchCode = {
      name: "searchCode",
      nameCode: "F3",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "F3"
    };
    this.deleteCode = {
      name: "deleteCode",
      nameCode: "Delete",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "Delete"
    };
    this.refreshCode = {
      name: "refreshCode",
      nameCode: "F5",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "F5"
    };
    this.detailCode = {
      name: "detailCode",
      nameCode: "F6",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "F6"
    };
    this.saveCode = {
      name: "saveCode",
      nameCode: "Ctrl + S",
      isCtrl: true,
      isShift: false,
      isAlt: false,
      key: "s"
    };
    this.quitCode = {
      name: "quitCode",
      nameCode: "Esc",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "Escape"
    };
    this.completeCode = {
      name: "completeCode",
      nameCode: "Enter",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "Enter"
    };
    this.printCode = {
      name: "printCode",
      nameCode: "Ctrl + P",
      isCtrl: true,
      isShift: false,
      isAlt: false,
      key: "p"
    };
    this.moveCode = {
      name: "moveCode",
      nameCode: "TAB",
      isCtrl: false,
      isShift: false,
      isAlt: false,
      key: "Tab"
    };
    let list = [
      this.addCode,
      this.editCode,
      this.searchCode,
      this.deleteCode,
      this.refreshCode,
      this.detailCode,
      this.saveCode,
      this.quitCode,
      this.completeCode,
      this.printCode,
      this.moveCode
    ];
    this.shortKeyService.insert(list);
    return this.$vs.notify({
      title: "Chúc mừng",
      text: "Bạn đã khôi phục thành công",
      color: "success"
    });
  }
  created() {
    this.shortKeyService.get().then((result: SearchResult<any>) => {
      this.addCode = result.items[0];
      this.editCode = result.items[1];
      this.searchCode = result.items[2];
      this.deleteCode = result.items[3];
      this.refreshCode = result.items[4];
      this.detailCode = result.items[5];
      this.saveCode = result.items[6];
      this.quitCode = result.items[7];
      this.completeCode = result.items[8];
      this.printCode = result.items[9];
      this.moveCode = result.items[10];
    });
  }
  getKeyCode() {}
}
