import {ActionResult} from "@/models/ActionResult";
import axios from "axios";
import Vue from 'vue';
import { SearchResult } from '@/models/SearchResult';

export default class ShortKeyService extends Vue {
    url = `http://localhost:6002/api/v1/keycodesetting`;

    // insert(order: object, search: object, customer: object, move: object): Promise<ActionResult<number>>{
    //     return axios.post(`${this.url}`, {
    //         params:{
    //             order: order,
    //             search: search,
    //             customer: customer,
    //             move: move,
    //         }
    //     });
    // }

    get(): Promise<SearchResult<any>> {        
        return axios.get(`${this.url}`);
    }

    insert(listKeyCode: object): Promise<ActionResult<number>> {
        return axios.post(`${this.url}`, listKeyCode);
    }

    // insert(name: string, nameCode: string, isCtrl: boolean, isShift: boolean, isAlt: boolean, key: string): Promise<ActionResult<number>> {
    //     return axios.post(`${this.url}`, {
    //         params: {
    //             name: name,
    //             nameCode: nameCode,
    //             isCtrl: isCtrl,
    //             isShift: isShift,
    //             isAlt: isAlt,
    //             key: key,
    //         }
    //     });
    // }

    update(orderKeyCode: string, searchKeyCode: string, customerKeyCode: string, moveKeyCode: string): Promise<ActionResult<number>> {
        return axios.put(`${this.url}`, {
            params: {
                orderKeyCode,
                searchKeyCode,
                customerKeyCode,
                moveKeyCode,
            }
        });
    }
}