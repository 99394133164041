









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ShortKeyInput extends Vue {
  @Prop() value: any;

  get currentValue() {
    return this.value;
  }

  set currentValue(value: any) {
    this.$emit("input", value);
  }

  private checkKeyCode(
    ctrlKey: boolean,
    shiftKey: boolean,
    altKey: boolean,
    key: string
  ) {
    let text = "";
    if (ctrlKey) {
      text += "Ctrl + ";
    }
    if (shiftKey) {
      text += "Shift + ";
    }
    if (altKey) {
      text += "Alt + ";
    }
    if (key) {
      text += key.toUpperCase();
    }
    return text;
  }

  getKeyCode(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();

    switch (event.key) {
      case "Control":
      case "Alt":
      case "Shift":
      case "CapsLock":
      case "Backspace":
      case " ":
      case "ArrowDown":
      case "ArrowLeft":
      case "ArrowUp":
      case "ArrowRight":
      case "Home":
      case "End":
      case "PageDown":
      case "PageUp":
      case "Insert":
      case "ContextMenu":
      case "Meta":
        return;
    }
    this.currentValue = {
      name: this.value.name,
      nameCode: this.checkKeyCode(
        event.ctrlKey,
        event.shiftKey,
        event.altKey,
        event.key
      ),
      isCtrl: event.ctrlKey,
      isShift: event.shiftKey,
      isAlt: event.altKey,
      key: event.key
    };
  }
}
