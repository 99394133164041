var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-col',{attrs:{"vs-w":"12"}},[_c('vx-card',{attrs:{"title":"Cấu hình phím tắt"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Thêm mới")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('addCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('addCode')},model:{value:(_vm.addCode),callback:function ($$v) {_vm.addCode=$$v},expression:"addCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Sửa")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('editCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('editCode')},model:{value:(_vm.editCode),callback:function ($$v) {_vm.editCode=$$v},expression:"editCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Tìm Kiếm")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('searchCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('searchCode')},model:{value:(_vm.searchCode),callback:function ($$v) {_vm.searchCode=$$v},expression:"searchCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Xóa")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('deleteCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('deleteCode')},model:{value:(_vm.deleteCode),callback:function ($$v) {_vm.deleteCode=$$v},expression:"deleteCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Tải lại trang")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('refreshCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('refreshCode')},model:{value:(_vm.refreshCode),callback:function ($$v) {_vm.refreshCode=$$v},expression:"refreshCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Xem chi tiết")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('detailCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('detailCode')},model:{value:(_vm.detailCode),callback:function ($$v) {_vm.detailCode=$$v},expression:"detailCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Lưu")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('saveCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('saveCode')},model:{value:(_vm.saveCode),callback:function ($$v) {_vm.saveCode=$$v},expression:"saveCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Thoát màn hình thêm mới")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('quitCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('quitCode')},model:{value:(_vm.quitCode),callback:function ($$v) {_vm.quitCode=$$v},expression:"quitCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Hoàn thành câu lệnh")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('completeCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('completeCode')},model:{value:(_vm.completeCode),callback:function ($$v) {_vm.completeCode=$$v},expression:"completeCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("In")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('printCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('printCode')},model:{value:(_vm.printCode),callback:function ($$v) {_vm.printCode=$$v},expression:"printCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Di chuyển qua lại các trường")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('moveCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ShortKeyInput',{attrs:{"danger":errors.length > 0,"label":_vm.$t('moveCode')},model:{value:(_vm.moveCode),callback:function ($$v) {_vm.moveCode=$$v},expression:"moveCode"}})]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-2/3 w-full ml-auto"},[_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.createKeyCode.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Cập nhật')))]),_c('vs-button',{staticClass:"mb-2",attrs:{"color":"warning","type":"border"},on:{"click":_vm.resetKeyCode}},[_vm._v("Khôi phục")])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }